import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';
import {
  TAgreementAnswer,
  TAgreementCheckbox,
  TAgreementConfiguration,
  TAgreementIncreasedRiskSessionType,
  TAgreementTextItem,
  TCreditRequest,
  TCustomer,
  TCustomerCheckIn,
  TPaymentType,
  TPhoneNumberString, TSessionConsumable, TSessionHealingRecommendation,
  TSessionType, TSessionTypeLocation,
  TTipsType,
  TUrlString,
} from '@/types/types';


const getCreditRequest = async (sessionId: number): Promise<TCreditRequest> => {
  const response = await axios.get(`${APIURL}/v1/credit_request/${sessionId}/`);
  return response.data;
}

type TAddCreditDocumentData = {
  personal_data_files: File[]
}
const addCreditDocument = async (
  id: number, // Session ID
  data: TAddCreditDocumentData
): Promise<void> => {
  const formData = new FormData()

  data.personal_data_files.forEach((file) =>
    formData.append('personal_data_files', file)
  )

  await axios.patch(`${APIURL}/v1/credit-add-documents/${id}/`, formData);
};

type TGetSessionByIdReturn = {
  id: number
  consumables: Array<TSessionConsumable>
  type: TSessionType
  customer: number
  location: TSessionTypeLocation | null
  healing_recommendation: TSessionHealingRecommendation | null
  customers_check_in: TCustomerCheckIn | null
  parlor: {
    id: number,
    instagram_url: TUrlString | null,
    google_maps_url: TUrlString | null
  }
}

const getSessionById = async (id: number): Promise<TGetSessionByIdReturn> => {
  const response = await axios.get(`${APIURL}/v1/sessions-customers/${id}/`)
  return response.data
}


type TAddCreditPaymentData = {
  amount: number,
  payment_method: TPaymentType,
  session_id: number
}
const addCreditPayment = async (data: TAddCreditPaymentData): Promise<TUrlString> => {
  const response = await axios.post(`${APIURL}/v1/customer/add-credit-payment/`, data)
  return response.data.payment_url
}


type TUpdateCreditDocumentData = {
  file: File
}

const updateCreditDocument = async (
  id: number, // Credit document ID,
  data: TUpdateCreditDocumentData,
): Promise<void> => {
  const formData = new FormData();
  formData.append('file', data.file);
  await axios.put(`${APIURL}/v1/update-document-file/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

type TAddConsumablesData = {
  item: number,
  value: number,
  attributes: number[]
}
const addConsumables = async (id: number, data: TAddConsumablesData[]) => {
  const response = await axios.patch(`${APIURL}/v1/customer-consumables/many/?session_pk=${id}`, data);
  return response.data;
};

type TPrepaymentData = {
  prepayment: number
  status: TPaymentType
  session_id: number
}
const addPrepayment = async (id: number, data: TPrepaymentData) => {
  const response = await axios.post(`${APIURL}/v1/sessions-prepayment/`, data);
  return response.data;
};

type TCreateIncreasedRiskAgreementData = {
  increased_risk_signature: string
}
const createIncreasedRiskAgreement = async (
  id: number, //Session ID
  data: TCreateIncreasedRiskAgreementData,
) => {
  const response = await axios.patch(`${APIURL}/v1/increased-risk-signature/${id}/`, data);
  return response.data;
};

type TCreateAgreementData = {
  answer: TAgreementAnswer
  signature: string
  session: number
}
const createAgreement = async (data: TCreateAgreementData) => {
  const response = await axios.post(`${APIURL}/v1/customers/agreement/`, data);
  return response.data;
};

type TAddTipsData = {
  amount: number
  payment_method: TPaymentType
  session: number
  type: TTipsType
}
type TAddTipsResponse = {
  payment_url: TUrlString
}
const addTips = async (data: TAddTipsData): Promise<TAddTipsResponse> => {
  const response = await axios.post(`${APIURL}/v1/customer/tips/`, data);
  return response.data;
};

type TUpdateCheckinData = {
  customers_check_in: TCustomerCheckIn
}

type TGetCheckInResponse = {
  customers_check_in: TCustomerCheckIn
}

const getCheckIn = async (
  id: number //session ID
): Promise<TGetCheckInResponse> => {
  const response =  await axios.get(`${APIURL}/v1/customer-check-in/${id}/`);
  return response.data
}
const updateCheckIn = async (
  id: number,//session ID
  data: TUpdateCheckinData,
): Promise<TUpdateCheckinData> => {
  const response = await axios.patch(`${APIURL}/v1/customer-check-in/${id}/`, data);
  return response.data;
};

type TGetCalculatedPriceParams = {
  parlor: number
  session_type: number
  session_type_location?: number
  style?: number
  size?: number
  colored?: boolean
}

type TGetCalculatedPriceReturn = {
  'id': number,
  'price': number,
  'session_type_id': number,
  'session_type_name': string,
  'location_id': number | null,
  'location_name': number | null,
  'size_coefficient': number,
  'style_coefficient': number,
  'location_coefficient': number,
  'colored_coefficient': number,
  'total': number
}

const getCalculatedPrice = async (params: TGetCalculatedPriceParams): Promise<TGetCalculatedPriceReturn> => {
  const response = await axios.get(`${APIURL}/v2/sessions/calculate-session-price`, { params });
  return response.data;
};

const createSession = () => {
};

type TGetSessionWithAgreementParams = {
  [key: string]: any
  phone_number: TPhoneNumberString
}
const getSessionWithAgreement = async (params: TGetSessionWithAgreementParams): Promise<number | null> => {
  const response = await axios.get(`${APIURL}/v2/sessions/session-agreement/`, { params });
  return response.data?.session_id;
};

type TGetAgreementInformationReturn = {
  id: number
  date: string
  time: string
  type: TSessionType,
  parlor: number,
  increased_risk: boolean
  increased_risk_information: TAgreementIncreasedRiskSessionType[]
  text_items: {
    healing?: TAgreementTextItem[]
    contraindication?: TAgreementTextItem[]
  }
  checkboxes: {
    agreement: TAgreementCheckbox[]
    contraindication: TAgreementCheckbox[]
    disease: TAgreementCheckbox[]
  }
  agreement: TAgreementConfiguration
  no_responsibility: boolean
  customer: TCustomer
}
const getAgreementInformation = async (
  id: number,
): Promise<TGetAgreementInformationReturn> => {
  const response = await axios.get(`${APIURL}/v2/sessions/agreement-information/${id}/`);
  return response.data;
};


type TAddTransferPhotoData = {
  session: number,
  images: string[],
}

const addTransferPhoto = async (data: TAddTransferPhotoData): Promise<any> => {
  const response = await axios.post(`${APIURL}/v2/sessions/photo-transfer-session/`, data);
  return response.data;
}

export {
  addConsumables,
  getAgreementInformation,
  addPrepayment,
  getCalculatedPrice,
  createAgreement,
  TGetCalculatedPriceReturn,
  TUpdateCreditDocumentData,
  updateCreditDocument,
  addCreditDocument,
  addCreditPayment,
  getCreditRequest,
  TAddCreditPaymentData,
  TGetAgreementInformationReturn,
  TCreateAgreementData,
  getSessionWithAgreement,
  createIncreasedRiskAgreement,
  getSessionById,
  TGetSessionByIdReturn,
  updateCheckIn,
  getCheckIn,
  TUpdateCheckinData,
  addTransferPhoto,
};