import * as customer from '@/api/customer/customer'
import * as student from '@/api/customer/student'
import * as session from '@/api/customer/session'
import * as cart from '@/api/customer/cart'
const customerApi = {
  customer,
  student,
  session,
  cart,
}

export { customerApi };