import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { UserLocalStorage } from '@/utils/localStorageConstants';

const redirectNavigation = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const redirectPath = from.query.redirect as string || undefined;
  if (redirectPath && redirectPath !== to.path) {
    console.log('redirect to ', redirectPath)

    if (to.fullPath === redirectPath) {
      console.log('Avoiding redirect loop');
      return false;
    }
    next(redirectPath);
    return true;
  } else {
    return false;
  }
};

const crmUserNavigation = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isUserAuthRequired = to.meta.userAuthRequired as boolean;
  const isUserAuth = !!localStorage.getItem(UserLocalStorage.refreshToken);

  if (isUserAuthRequired && !isUserAuth) {
    next('/user/login');
    return true;
  } else {
    return false;
  }
};

const customerUserNavigation = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const isAuthRequired = to.meta.requiresAuth as boolean;
  const isAuthenticated = !!localStorage.getItem('accessToken');

  if (isAuthRequired && !isAuthenticated) {
    next({ path: '/client/login', query: { target: to.path } });
    return true;
  } else {
    return false;
  }
};

export {
  redirectNavigation,
  crmUserNavigation,
  customerUserNavigation,
};