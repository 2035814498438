const UserLocalStorage = {
  userId: 'crm_userId',
  accessToken: 'crm_accessToken',
  refreshToken: 'crm_refreshToken',
};

const CustomerLocalStorage = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  userId: 'userId',
}

export { UserLocalStorage, CustomerLocalStorage };