<script setup lang="ts">
const props = defineProps({
  context: Object,
});

const count = Number(props.context.count || 5);
const icon = props.context.icon || 'bi-star';
const iconActive = props.context.iconActive || 'bi-star-fill';
const handleInput = (i: number) => {
  props.context.node.input(i);
};
</script>

<template>
  <div class="rate">
    <span
      class="rate__elem"
      v-for="i in count" :key="i"
    >
      <input
        class="elem-input"
        :name="props.context.node.name"
        :id="props.context.node.name + '_' + i"
        :checked="props.context._value === i"
        @input="handleInput(i)" type="radio"
      />
      <label
        class="elem-label"
        :for="props.context.node.name + '_' + i"
      >
        <i
          class="bi"
          :class="
            (isNaN(Number(props.context.value)) || (i > Number(props.context.value)))
              ? icon
              : iconActive
          "
        />
      </label>
    </span>
  </div>
</template>

<style scoped lang="scss">
.rate {
  font-size: 1.5rem;

  &__elem {
    .elem-input {
      display: none;
    }

    .elem-label {
      cursor: pointer;
      transition: all .3s ease-in-out;
    }
  }

  &__elem + &__elem {
    margin-left: .25rem;
  }
}
</style>