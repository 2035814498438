export const mobile = {
  mounted(el) {
    const isMobile = window.innerWidth <= 768; // Пример порогового значения ширины для мобильных устройств
    if (!isMobile) {
      el.remove();
    }
  },
};

export const desktop = {
  mounted(el) {
    const isMobile = window.innerWidth <= 768; // Пример порогового значения ширины для мобильных устройств
    if (isMobile) {
      el.remove();
    }
  },
};
