import { getDomainLocale, getMainSiteDomain } from '@/utils/url';
import VueGtag, { PluginOptions } from 'vue-gtag';

const domainToGAId = {
  'customer.vean-tattoo.bg': 'G-PEZ3PEQSJ1',
  'customer.vean-tattoo.cz': 'G-GXG1WHYN9E',
  'customer.vean-tattoo.de': 'G-3K9Q6Y98S8',
  'customer.vean-tattoo.es': 'G-JZ1K9J9X47',
  'customer.vean-tattoo.hu': 'G-0D8MGJCTY9',
  'customer.vean-tattoo.lt': 'G-HT331W44KS',
  'customer.vean-tattoo.lv': 'G-YMBVVRW5KD',
  'customer.vean-tattoo.nl': 'G-YNP2RRWC7Q',
  'customer.vean-tattoo.pl': 'G-67CFF2CL7C',
  'customer.vean-tattoo.com': 'G-5SWVFCFJW4',
  'customer.vean-tattoo.uk': 'G-S7YKTTS1C3',
  'customer.vean-tattoo.sk': 'G-QY7482X034',
  'customer.vean-tattoo.ca': 'G-5DP5QYNGKE',
  'customer.vean-tattoo.ro': 'G-Q5T82ED3GP',
  'customer.vean-tattoo.ee': 'G-0804ZGG5FG',
  'localhost': '-',
};

const currentHostname = window.location.hostname;
// @ts-ignore
const gaId = domainToGAId[currentHostname] || '-';

const gtagOptions: PluginOptions = {
  config: {
    id: gaId,
  },
};

const acceptDomains: string[] = [
  getMainSiteDomain(),
  window.location.host,
]

export {
  VueGtag,
  gtagOptions,
  acceptDomains,
};