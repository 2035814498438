import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloClients } from '@vue/apollo-composable';
import { CustomerLocalStorage, UserLocalStorage } from '@/utils/localStorageConstants';
import { createApolloProvider } from '@vue/apollo-option';

const getApolloDomain = () => {
  const { hostname } = window.location;
  if (hostname === 'localhost') return 'http://localhost:8080/v1/graphql';
  const splited = hostname.split('.').slice(-2).pop();
  if (splited === 'pl' || splited === 'lv') {
    return `https://gq.vean-tattoo.${splited}/v1/graphql`;
  }
  return `https://gql.vean-tattoo.${splited}/v1/graphql`;
};

// Общий HTTP Link
const httpLink = createHttpLink({
  uri: getApolloDomain(),
});


// Общий Error Link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.error(
        `GraphQL error:\nMessage: ${err.message}\nCode: ${err.extensions.code}\nPath: ${err.extensions.path}`,
      );
    }
  }
  if (networkError) {
    console.error(`Network error:\n${networkError}`);
  }
});

// Первый Auth Link для клиента Customer
const authLinkCustomer = setContext((_, { headers }) => {
  const token = localStorage.getItem(CustomerLocalStorage.accessToken) || null;
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

// Создание первого Apollo Client (Customer)
export const apolloClientCustomer = new ApolloClient({
  link: authLinkCustomer.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});

// Второй Auth Link для клиента User
const authLinkUser = setContext((_, { headers }) => {
  const token = localStorage.getItem(UserLocalStorage.accessToken) || null;
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

// Создание второго Apollo Client (User)
export const apolloClientUser = new ApolloClient({
  link: authLinkUser.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});


const apolloProvider = createApolloProvider({
  clients: {
    default: apolloClientCustomer,
    userClient: apolloClientUser,
  },
  defaultClient: apolloClientCustomer,
})

// Предоставление клиентов Apollo
export function setupApollo(app) {
  app.provide(
    ApolloClients, {
      default: apolloClientCustomer,
      userClient: apolloClientUser,
    },
  );
  app.use(apolloProvider)
}
