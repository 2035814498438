import { onMounted, Ref, ref } from 'vue';

export default (): Ref<string | null> => {
  const countryCode = ref<string | null>(null)

  onMounted(async () => {
    const response = await fetch("https://ipwho.is");
    const { country_code } = await response.json();
    countryCode.value =  country_code;
  })

  return countryCode
}