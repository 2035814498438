import axios from "axios";
// import { refreshAccessToken } from "@/apiService";

const customerLang = localStorage.getItem("customer_lang");

if (customerLang) {
  axios.defaults.headers.common["Accept-Language"] = customerLang;
}

// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = localStorage.getItem("refreshToken");
//       const newAccessToken = await refreshAccessToken(refreshToken);
//       if (newAccessToken) {
//         axios.defaults.headers.common["Authorization"] =
//           `Bearer ${newAccessToken}`;
//         return axios(originalRequest);
//       }
//     }
//     return Promise.reject(error);
//   },
// );

export default axios;
