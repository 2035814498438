import { useToast } from 'maz-ui';

export default function() {
  const { toast } = useToast();

  const handleAxiosError = (error) => {
    console.error(error)
    let errorMessage = error?.response?.data;

    if (!errorMessage) {
      errorMessage = 'Error! ';
    } else if (typeof errorMessage === 'object' && errorMessage !== null) {
      // Проверка, является ли errorMessage объектом и не null
      if (Array.isArray(errorMessage)) {
        errorMessage = errorMessage[0];
      } else {
        const firstKey = Object.keys(errorMessage)[0];
        if (Array.isArray(errorMessage[firstKey])) {
          errorMessage = errorMessage[firstKey][0];
        } else {
          errorMessage = errorMessage[firstKey];
        }
      }
    } else if (typeof errorMessage !== 'string') {
      errorMessage = 'Something went wrong...';
    }

    toast.error(errorMessage);
  };

  const handleSuccess = (msg = '') => {
    toast.success(msg || 'Успех!');
  };

  return {
    handleAxiosError,
    handleSuccess,
  };
}
