<script setup lang="ts">
import { MazPhoneNumberInput } from 'maz-ui/components';
import { Result } from 'maz-ui/components/MazPhoneNumberInput/types';
import useCountryCode from '@/composables/useCountryCode';

const props = defineProps({
  context: Object,
});

const countryCode = useCountryCode();
const handleInput = (e: Result) => {
  if (e.isValid) {
    props.context.node.input(e.e164);
  } else props.context.node.input('');
};
</script>

<template>
  <MazPhoneNumberInput
    @update="handleInput"
    class="w-100"
    size="sm"
    fetch-country
    show-code-on-list
    no-flags
    :ignoredCountries="['RU']"
    :default-country-code="countryCode"
    :placeholder="$t('promotion.phone')"
  />
</template>

<style scoped lang="scss">
:deep(.m-input-wrapper) {
  border: none !important;
  background-color: transparent !important;
}
</style>