import axios from '@/plugins/axios';
import { APIURL } from '@/utils/url';
import {
  TComplain,
  TCustomerAccommodationStatus,
  TCustomerDisturb,
  TCustomerGender,
  TCustomerLifestyleStatus,
  TCustomerPierceCountStatus,
  TCustomerPreferredStyleStatus,
  TCustomerTattooCountStatus,
  TCustomerYearsOld, TEmailString,
  TLanguages, TPhoneNumberString,
  TProblemSituation,
} from '@/types/types';
import { AxiosResponse } from 'axios';


type TEditCustomerData = {
  full_name?: string; // Full name
  email?: string; // Email address
  phone_number?: string; // Phone number
  find_out?: number; // Find out
  parlor?: number; // Parlor
  verification_code?: string; // Verification code
  birth_date?: string; // Date of Birth, nullable
  social_media_link?: string; // Social Media Link
  active_sms?: boolean; // Active sms
  gender?: TCustomerGender; // Gender
  years_old?: TCustomerYearsOld; // Years old
  do_not_disturb?: TCustomerDisturb; // Do not disturb
  language?: TLanguages; // Language, nullable
  accommodation_status?: TCustomerAccommodationStatus; // Статус проживания
  second_phone_number?: string; // Second phone number
  preferred_style_status?: TCustomerPreferredStyleStatus; // Стиль предпочитаемый
  lifestyle_status?: TCustomerLifestyleStatus; // Лайфстайл
  tattoo_count_status?: TCustomerTattooCountStatus; // Тату
  pierce_count_status?: TCustomerPierceCountStatus; // Пирсинг
};
const editCustomer = async (
  id: number, //Customer ID
  data: TEditCustomerData,
) => {
  const response = await axios.patch(`${APIURL}/v1/customers/${id}/`, data);
  return response.data;
};


type TInviteFriendData = {
  email: string
  referral: number
}
const inviteFriend = async (data: TInviteFriendData) => {
  const response = await axios.post(`${APIURL}/v1/customers/`, data);
  return response.data;
};

type TChangePasswordData = {
  user_id: number,
  old_password: string,
  new_password: string
}
const changePassword = async (data: TChangePasswordData) => {
  const response = await axios.post(`${APIURL}/v2/roulettes/change-password/`, data);
  return response.data;
};

type TForgotPasswordData = {
  phone?: TPhoneNumberString,
  email?: TEmailString,
  type: 'phone' | 'email'
}
const forgotPassword = async (data: TForgotPasswordData) => {
  const response = await axios.post(`${APIURL}/v2/roulettes/forgot-password/`, data);
  return response.data;
};

type TGenerateSketchData = {
  prompt: string,
  created_by_customer: number,
  where_generated: 'customer'
}
const generateSketch = async (data: TGenerateSketchData) => {
  const response = await axios.post(`${APIURL}/v1/image-ai/`, data);
  return response.data;
};

type TChangePreferLangaugeData = {
  id: number, //Customer id
  language: string,
}
const changePreferLangauge = async (data: TChangePreferLangaugeData) => {
  const response = await axios.post(`${APIURL}/v2/roulettes/change-information/`, data);
  return response.data;
};


type TCreateComplainData = TComplain
const createComplain = async (data: TCreateComplainData) => {
  const response = await axios.post(`${APIURL}/v2/roulettes/complains/`, data);
  return response.data;
};

type TBlockAccountData = {
  password: string
}
const blockAccount = async (
  id: number, //Customer id
  data: TBlockAccountData,
) => {
  const response = await axios.post(`${APIURL}/v1/auth/block-customer/${id}/`, data);
  return response.data;
};

type TCreateProblemSituationData = TProblemSituation
const createProblemSituation = async (data: TCreateProblemSituationData) => {
  const response = await axios.post(`${APIURL}/v2/sessions/problem-situation/`, data);
  return response.data;
};

type TVerifyData = {
  phone_number?: string,
  email?: string,
  send_type: 'email' | 'sms'
}
const verify = async (data: TVerifyData) => {
  const response = await axios.post(`${APIURL}/v1/customers/validate/`, data);
  return response.data;
};


type TUpdateCodeData = {
  verification_code: string,
}

type TGoogleVerifyData = {
  user_info: string,
  customer_id: number,
}
const googleVerify = async (data: TGoogleVerifyData) => {
  const response = await axios.patch(`${APIURL}/v1/google-verificate/`, data);
  return response.data;
};

type TAppleVerifyData = {
  user_info: string,
  customer_id: number,
}
const appleVerify = async (data: TAppleVerifyData) => {
  const response = await axios.patch(`${APIURL}/v1/apple-verificate/`, data);
  return response.data;
};

type TGetTokensData = {
  username: string
  password: string
}
type TGetTokensResponse = {
  access: string
  refresh: string
}
const getTokens = async (data: TGetTokensData): Promise<TGetTokensResponse> => {
  const response = await axios.post(`${APIURL}/v1/auth/customer/jwt/obtain/`, data);
  return response.data;
};

type TRefreshAccessTokenData = {
  refresh: string
}
type TRefreshAccessTokenResponse = {
  access: string
}
const refreshAccessToken = async (data: TRefreshAccessTokenData): Promise<TRefreshAccessTokenResponse> => {
  const response = await axios.post(`${APIURL}/v1/auth/customer/jwt/refresh/`, data);
  return response.data;
};

const getPasses = async (
  id: number //Customer ID
) => {
  const response = await axios.get(`${APIURL}/v1/create_passes/`, {
      responseType: 'blob',
      params: {
        customer: id,
      },
    },
  );
  return response.data
};

type TUpdateAvatarData = {
  avatar: string
  customer_id: number
}
const updateAvatar = async (data: TUpdateAvatarData) => {
  const response = await axios.post(`${APIURL}/v1/create_passes/`, data)
  return response.data
};

type TGetCustomersCountParams = {
  phone_number?: string
  email?: string
  exclude_id?: number
}

type TGetCustomersCountResponse = {
  count: number
}

const getCustomersCount = async (params:TGetCustomersCountParams):Promise<TGetCustomersCountResponse> => {
  const response = await axios.get(`${APIURL}/v1/customers-count/`, {params})
  return response.data
}

export {
  inviteFriend,
  getCustomersCount,
  changePassword,
  forgotPassword,
  generateSketch,
  changePreferLangauge,
  createComplain,
  blockAccount,
  createProblemSituation,
  verify,
  googleVerify,
  appleVerify,
  editCustomer,
  getTokens,
  refreshAccessToken,
  getPasses,
  updateAvatar,

  TForgotPasswordData,
};

