import { getTranslatedField } from '@/utils/gqlHelper';
import { TOption } from '@/types/types';

export default <TArrayEl extends Record<string, any>, TOptionValue = string>(
  array: TArrayEl[] | undefined | null,
  labelKey: keyof TArrayEl,
  valueKey: keyof TArrayEl,
  options?: {
    useTranslatedField?: boolean
    sorted?: boolean
  }
): TOption<TOptionValue>[] => {
  let optionsArray = array?.map((el) => ({
    label: options?.useTranslatedField ? getTranslatedField(el, labelKey as string) : el[labelKey],
    value: el[valueKey],
  })) || [];

  if (options?.sorted) {
    optionsArray = optionsArray.sort((a, b) => String(a.label).localeCompare(String(b.label)))
  }


  return optionsArray
};
