import { App } from 'vue';

export type TCountryCode =
  string

export interface IOptions {
  countryCode: TCountryCode;
}

export default {
  install(app: App, options: IOptions) {
    const { countryCode } = options;

    const keys: Record<TCountryCode, string> = {
      uk: 'GTM-5ZBZRMQH',
      bg: 'GTM-WF4XF2NQ',
      ca: 'GTM-5X8G4Q9H',
      com: 'GTM-KM3WW32B',
      cz: 'GTM-W6682M79',
      de: 'GTM-T3VH8D7P',
      es: 'GTM-PF2DTM96',
      hu: 'GTM-WZPXTG92',
      lt: 'GTM-5BP7FK6T',
      lv: 'GTM-M66J2CFJ',
      nl: 'GTM-52Q9NH5D',
      pl: 'GTM-TCJR229X',
      sk: 'GTM-58BKN4RL',
      ro: 'GTM-5J6HS5DS',
      fr: 'GTM-P955KJXQ',
      ee: 'GTM-PZ9M2C7M'
    };

    const getScript = (countryCode: TCountryCode): string => {
      const key = keys[countryCode];
      return `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${key}');
      `;
    };

    const getNoScript = (countryCode: TCountryCode): string => {
      const key = keys[countryCode];
      return `<iframe src="https://www.googletagmanager.com/ns.html?id=${key}" height="0" width="0"
              style="display:none;visibility:hidden"></iframe>`;
    };

    // Функция для загрузки GTM
    const loadGTM = (): void => {
      if (document.querySelector('script[data-gtm-loaded]')) {
        return; // Если скрипт уже загружен, повторная загрузка не требуется
      }

      const scriptContent = getScript(countryCode);
      if (scriptContent) {
        const script = document.createElement('script');
        script.async = true;
        script.innerHTML = scriptContent;
        script.setAttribute('data-gtm-loaded', 'true');
        document.head.appendChild(script);
      }

      const noScriptContent = getNoScript(countryCode);
      if (noScriptContent) {
        const noscript = document.createElement('noscript');
        noscript.innerHTML = noScriptContent;
        document.head.appendChild(noscript);
      }
    };

    loadGTM();
  },
};