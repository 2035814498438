import { createI18n } from 'vue-i18n';
import ru from './ru';
import en from './en';
import uk from './uk';
import pl from './pl';
import es from './es';
import cs from './cs';
import de from './de';
import nl from './nl';
import hu from './hu';
import lt from './lt';
import bg from './bg';
import lv from './lv';
import et from './et'
import sk from './sk';

export function getCurrentLanguage() {
  try {
    return navigator.language.split('-')[0];
  } catch {
    return 'en';
  }
}

export default createI18n({
  locale: localStorage.getItem('customer_lang') || getCurrentLanguage(),
  fallbackLocale: 'en',
  messages: { ru, en, uk, pl, es, cs, de, nl, hu, lt, bg, lv, et, sk },
  globalInjection: true,
  legacy: false,
});

export const languageNames = {
  uk: 'Українська',
  ru: 'Русский',
  en: 'English',
  pl: 'Polski',
  es: 'Español',
  cs: 'Čeština',
  de: 'Deutsch',
  nl: 'Nederlands',
  hu: 'Magyar',
  lt: 'Lietuvių',
  bg: 'Български',
  lv: 'Latviešu',
  et: 'Eesti',
  sk: 'Slovenčina'
};
