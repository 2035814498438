<script setup>
import SignaturePad from '@/components/SignaturePad.vue';
import { ref, watch } from 'vue';

const props = defineProps({
  context: Object,
})

const signaturePad = ref()

watch(signaturePad, (value) => {
  props.context.node.input(value?.data)
})
</script>

<template>
  <SignaturePad style="height: 300px; width: 100%; border: none; border-radius: 0" v-model="signaturePad" />
</template>