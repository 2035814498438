<template>
  <div
    class="loading-container d-flex align-items-center justify-content-center"
  >
    <div class="custom-loader"></div>
  </div>
</template>

<script>
export default {
  name: "CustomLoading",
};
</script>

<style lang="scss">
.loading-container {
  width: 100%;
  height: 300px;
  .custom-loader {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 50%;
    border: 0.25rem solid transparent;
    border-right-color: var(--primary);
    animation: s2 0.75s infinite linear;
  }
}

@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}
</style>
